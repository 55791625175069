<template>
 <div>
  <b-card bg-variant="light">
    <h1>Welcome</h1>
    <h2>Please sign in to continue</h2>
  </b-card>
 </div>
</template>

<script>
import {http_v1} from '@/axios'

export default {
  data () {
    return {
    }
  },
  methods: {
  },
  mounted() {
    if (this.$route.query.token_id) {
      http_v1.get (
        'validate_registration', {
          params: {
            token_id: this.$route.query.token_id
          }
        }
      ).then(response => {
        if(response.data && response.data.auth_token) {
          localStorage.token = response.data.auth_token
          // this.$router.back()
        }
      }).catch(error => {
        if( error.response && error.response.data) {
          var message = error.response.data.message || JSON.stringify(error.response.data)
          if (error.response.status === 500 ) message = "500 Server Error"
          this.$bvToast.toast(message, {
            title: 'Error',
            autoHideDelay: 5000,
            appendToast: true
          })
        }
      })
    }
  }
}
</script>